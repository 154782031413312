import React from 'react'
import styled from 'styled-components/macro'

const StyledBG = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background-image: linear-gradient(-20deg, #b721ff 0%, #21d4fd 100%);
  animation: hueRotate infinite 20s;
  z-index: -1;
  @keyframes hueRotate {
    0% {
      filter: hue-rotate(0deg);
    }
    50% {
      filter: hue-rotate(45deg);
    }
    100% {
      filter: hue-rotate(0deg);
    }
  }
`

export default class HueRotateBG extends React.Component {
  render() {
    return (
      <StyledBG />
    )
  }
}