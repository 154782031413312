import React from 'react'
import styled from 'styled-components/macro'
import Button from "./Button.js"

const StyledCTA = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-image: linear-gradient(to bottom, #fb9779 0%, #feb464 100%);
  padding: 80px 20px 125px;
  margin-bottom: -60px;
  h2 {
    font-size: 48px;
    color: #fff;
    text-align: center;
    max-width: 800px;
  }
  p {
    width: 100%;
    max-width: 540px;
    margin: 0px auto 35px;
    text-align: center;
  }
`
const StyledLink = styled.a`
    font-family: 'Apercu Medium', sans-serif;
    color: #9A67C6;
    border-bottom: 1px solid transparent;
    text-decoration: none;
    transition: all 200ms ease;
`


export default class Cta extends React.Component {
  render() {
    return (
      <StyledCTA>
        <h2>Want to learn how to write better cold emails?</h2>
        <p>If you like Cold Email Grader, you'll love what's next.</p>
        <StyledLink href="https://go.regie.io/cold-email-grader" target="_blank" rel="noopener noreferrer">
          <Button text="Let's talk" />
        </StyledLink>
      </StyledCTA>
    )
  }
}