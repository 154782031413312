import React from 'react'
import styled from 'styled-components/macro'
import glare from '../assets/img/glare.svg'

const StyledGlare = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 70%;
  height: 100%;
  background: url(${glare}) no-repeat;
  z-index: 0;
`

export default class Glare extends React.Component {
  render() {
    return (
      <StyledGlare />
    )
  }
}