import React from 'react'
import styled from 'styled-components/macro'
import LogoDark from '../components/LogoDark.js'

const Container = styled.div`
  width: 100%;
  max-width: 880px;
  margin: 0 auto;
  padding: 50px 20px 150px;
  strong {
    font-family: 'Apercu Bold', sans-serif;
    font-weight: normal;
  }
  p {
    color: #2e2e2e;
    margin-bottom: 35px;
  }
  h4 {
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    color: #2e2e2e;
    margin: 50px 0 35px;
  }
  td {
    padding: 10px;
  }
  ul {
    list-style-type: disc;
    margin: 35px 0 35px 30px;
  }
    ul li {
      font-family: 'Apercu Light', sans-serif;
      font-weight: normal;
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0.2px;
    }
  table {
    margin-bottom: 50px;
  }
    table td {
      border: 1px solid #2e2e2e;
    }
  .textLink:link,
  .textLink:visited {
    color: #13A4F2;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: all 200ms ease;
    padding-bottom: 1px;
    font-family: 'Apercu Medium', sans-serif;
  }
    .textLink:hover,
    .textLink:active {
      border-bottom: 1px solid #13A4F2;
    }
`

export default class PrivacyPolicy extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <Container>
        <LogoDark />
        <h2>Privacy Policy</h2>
        <p>Last Updated: September 8, 2020</p>
        <p>This Privacy Policy explains how information about you is collected, used
          and disclosed by RevVision, LLC, when you use our websites, mobile applications
          and other online products and services (collectively, the “Services”) or when
          you otherwise interact with us.
        </p>
        <p>
          We may change this Privacy Policy from time to time. If we make changes, we
          will notify you by revising the date at the top of the policy and, in some
          cases, we may provide you with additional notice (such as adding a statement to
          our websites’ homepages or sending you a notification). We encourage you to
          review the Privacy Policy whenever you access the Services to stay informed
          about our information practices and the ways you can help protect your
          privacy.
        </p>
        <p><strong>COLLECTION OF INFORMATION</strong></p>
        <p>
          <strong>INFORMATION YOU PROVIDE TO US</strong>
        </p>
        <p>
          We collect information you provide directly to us. For example, we may
          collect information when you fill out a form, create an account, use the
          Services to send or receive messages, including photos or videos uploaded into
          our Services, request customer support or otherwise communicate with us. The
          types of information we may collect include your username, password, email
          address, phone number, age, payment information, and any other information you
          choose to provide.
        </p>
        <p>
          <strong>INFORMATION WE COLLECT AUTOMATICALLY WHEN YOU USE THE SERVICES</strong>
        </p>
        <p>
          When you access or use our Services, we may automatically collect
          information about you, including:
        </p>
        <p>
          USAGE INFORMATION: When you upload content to our Services, we collect
          information about this content, including the time and date.
        </p>
        <p>
          LOG INFORMATION: We log information about your use of our websites,
          including your browser type and language, access times, pages viewed, your IP
          address and the website you visited before navigating to our websites
        </p>
        <p>
          DEVICE INFORMATION: We may collect information about the computer or device
          you use to access our Services, including the hardware model, operating system
          and version, MAC address, unique device identifier, phone number, International
          Mobile Equipment Identity (“IMEI”) and mobile network information. In addition,
          the Services may access your device’s native phonebook and image storage
          applications, with your consent, to facilitate your use of certain features of
          the Services.
        </p>
        <p>
          LOCATION INFORMATION: With your consent, we may collect information about
          the location of your device to facilitate your use of certain features of our
          Services, determine the speed at which your device is traveling, add
          location-based filters to your content, and for any other purpose described in
          this privacy policy.
        </p>
        <p>
          INFORMATION COLLECTED BY COOKIES AND OTHER TRACKING TECHNOLOGIES: We use
          various technologies to collect information, and this may include sending
          cookies to you. Cookies are small data files stored on your hard drive or in
          device memory that help us to improve our Services and your experience, see
          which areas and features of our Services are popular and count visits. We may
          also collect information using web beacons (also known as “tracking
          pixels”).
        </p>
        <p>
          Below is a list of the cookies we use, and the purposes for which we use
          them.  Please note that we do not control these cookies .  To find out more
          information about each cookie, please click on the link provided.
        </p>
        <table>
          <tbody>
            <tr>
                <td>Name of Cookie
                </td>
                <td>Name of Platform
                </td>
                <td>Purpose of Cookie
                </td>
                <td>More Information
                </td>
            </tr>
            <tr>
                <td>_ga
                </td>
                <td>Google Analytics
                </td>
                <td>This cookie is installed by Google Analytics. The cookie is used to
                  calculate visitor, session, campaign data and keep track of site usage for the
                  site’s analytics report. The cookies store information anonymously and assign a
                  randomly generated number to identify unique visitors.  
                </td>
                <td><a className="textLink" href="https://support.google.com/analytics/answer/6004245">Additional resources</a>
                </td>
            </tr>
            <tr>
                <td>_gid
                </td>
                <td>Google Analytics
                </td>
                <td>This cookie is installed by Google Analytics. The cookie is used to
                  store information of how visitors use a website and helps in creating an
                  analytics report of how the website is doing. The data collected including the
                  number visitors, the source where they have come from, and the pages visited in
                  an anonymous form.  
                </td>
                <td><a className="textLink" href="https://support.google.com/analytics/answer/6004245">Additional resources</a>
                </td>
            </tr>
            <tr>
                <td>_gat_gtag_UA_39229043_1  
                </td>
                <td>Google Analytics
                </td>
                <td>Google uses this cookie to distinguish users.  
                </td>
                <td><a className="textLink" href="https://support.google.com/analytics/answer/6004245">Additional resources</a>
                </td>
            </tr>
          </tbody>
        </table>
        <p>
          <strong>INFORMATION WE COLLECT FROM OTHER SOURCES</strong>
        </p>
        <p>
          We may also obtain information from other sources and combine that with
          information we collect through our Services.
        </p>
        <p>
          <strong>USE OF INFORMATION</strong>
        </p>
        <p>
          We may use information about you for various purposes, including to:
        </p>
        <ul>
          <li>Provide, maintain and improve our Services, including to provide
              additional features;
          </li>
          <li>Provide and deliver the products and services you request, process
              transactions and send you related information;
          </li>
          <li>Respond to your comments, questions and requests and provide customer
              service;
          </li>
          <li>Send you technical notices, updates, security alerts and support and
              administrative messages;
          </li>
          <li>Communicate with you about products, services, offers, promotions,
              rewards, and events offered by a RevVision LLC service and others, and provide
              news and information we think will be of interest to you;
          </li>
          <li>Monitor and analyze trends, usage and activities in connection with our
              Services;
          </li>
          <li>Personalize and improve the Services and provide advertisements, content
              or features that match user profiles or interests;
          </li>
          <li>Link or combine with information we get from others to help understand
              your needs and provide you with better service;
          </li>
          <li>Detect, investigate and/or prevent fraudulent, unauthorized or illegal
              activity; and
          </li>
          <li>Carry out any other purpose for which the information was
              collected.
          </li>
        </ul>
        <p>
          RevVision LLC is based in the United States and the information we collect
          is governed by U.S. law. By accessing or using the Services or otherwise
          providing information to us, you consent to the processing and transfer of
          information in and to the U.S. and other countries.
        </p>
        <p>
          <strong>SHARING OF INFORMATION</strong>
        </p>
        <p>
          We may share information about you as follows or as otherwise described in
          this Privacy Policy:
        </p>
        <ul>
          <li>With other users who may access our content;</li>
          <li>With vendors, consultants and other service providers who need access to
              such information to carry out work on our behalf;
          </li>
          <li>Where appropriate, we may also share your personal information with
              selected third party partners to help us provide you Services, or to fulfill
              your requests, or with your consent. When selecting our suppliers and partners,
              we take into account their data handling processes.
          </li>
          <li>In response to legal process or a request for information if we believe
              disclosure is in accordance with any applicable law, rule, or regulation, or as
              otherwise required by any applicable law, rule or regulation;
          </li>
          <li>In order to investigate or remedy potential violations of our user
              agreements or policies, or to protect the rights, property and safety of
              RevVision LLC, our users or others;
          </li>
          <li>In connection with, or during negotiations of, any merger, sale of
              company assets, financing or acquisition of all or a portion of our business to
              another company; and
          </li>
          <li>With your consent or at your direction.</li>
        </ul>
        <p>
          We may also share aggregated or de-identified information, which cannot
          reasonably be used to identify you.
        </p>
        <p>
          <strong>ANALYTICS SERVICES PROVIDED BY OTHERS</strong>
        </p>
        <p>
          We may allow others to provide analytics services on our behalf. These
          entities may use cookies, web beacons and other technologies to collect
          information about your use of the Services and other websites, including your IP
          address, web browser, pages viewed, time spent on pages, links clicked and
          conversion information. This information may be used to, among other things,
          analyze and track data, determine the popularity of certain content and better
          understand your online activity. 
        </p>
        <p>
          We currently use Google Analytics which is a web analytics tool that helps
          us understand how users engage with our Services. Like many services, Google
          Analytics uses first-party cookies to track user interactions as in our case,
          where they are used to collect information about how users use our Services.
          This information is used to compile reports and to help us improve our Services.
          The reports disclose website trends without identifying individual
          visitors.
        </p>
        <p>
          <strong>DO NOT TRACK DISCLOSURE</strong>
        </p>
        <p>
          We do not track our users over time and across third party websites to
          provide targeted advertising.  We therefore do not respond to Do Not Track (DNT)
          signals. However, some third party sites do keep track of your browsing
          activities when they serve you content, which enables them to tailor what they
          present to you. Your browser should allow you to set its DNT signal so that
          third parties know you do not wish to be tracked.
        </p>
        <p>
          <strong>LEGAL BASIS FOR PROCESSING PERSONAL INFORMATION</strong>
        </p>
        <p>
          If you are from the European Economic Area (“EEA”), the UK or Switzerland,
          our legal basis for collecting and using the personal information described
          above will depend on the personal information concerned and the specific context
          in which we collect it.
        </p>
        <p>
          However, we will normally collect personal information from you only (i)
          where we need the personal information to perform a contract with you, (ii)
          where the processing is in our legitimate interests and not overridden by your
          rights, or (iii) where we have your consent to do so.  In some cases, we may
          also have a legal obligation to collect personal information from you or may
          otherwise need the personal information to protect your vital interests or those
          of another person.
        </p>
        <p>
          If we ask you to provide personal information to comply with a legal
          requirement or to perform a contact with you, we will make this clear at the
          relevant time and advise you whether the provision of your personal information
          is mandatory or not (as well as of the possible consequences if you do not
          provide your personal information).
        </p>
        <p>
          If we collect and use your personal information in reliance on our
          legitimate interests (or those of any third party), this interest will normally
          be to operate our platform and communicating with you as necessary to provide
          our Services to you and for our legitimate commercial interest, for instance,
          when responding to your queries, improving our platform, undertaking marketing,
          or for the purposes of detecting or preventing illegal activities.  We may have
          other legitimate interests and if appropriate we will make clear to you at the
          relevant time what those legitimate interests are.
        </p>
        <p>
          If you have questions about or need further information concerning the legal
          basis on which we collect and use your personal information, please contact us
          at <a className="textLink" href="mailto:seerevvision@gmail.com" target="_blank" rel="noopener noreferrer">seerevvision@gmail.com</a>.
        </p>
        <p>
          <strong>SECURITY</strong>
        </p>
        <p>
          We make reasonable efforts to ensure a level of security appropriate to the
          risk associated with the processing of information we collect about you. We
          maintain organizational, technical and administrative measures designed to
          protect your information within our organization against unauthorized access,
          destruction, loss, alteration or misuse. Your information is only accessible to
          a limited number of personnel who need access to the information to perform
          their duties. Unfortunately, no data transmission or storage system can be
          guaranteed to be 100% secure. If you have reason to believe that your
          interaction with us is no longer secure, please email us immediately at <a className="textLink" href="mailto:seerevvision@gmail.com" target="_blank" rel="noopener noreferrer">seerevvision@gmail.com</a>.
        </p>
        <p>
          <strong>INTERNATIONAL TRANSFERS</strong>
        </p>
        <p>
          Personal information may be stored and processed in any country where we
          have operations or where we engage service providers. We may transfer your
          information that we maintain about you to recipients in countries other than the
          country in which the information was originally collected, including to the
          United States. Those countries may have data protection rules that are different
          from those of your country. However, we will take measures to ensure that any
          such transfers comply with applicable data protection laws and that your
          information remains protected to the standards described in this Privacy Policy.
          In certain circumstances, courts, law enforcement agencies, regulatory agencies
          or security authorities in those other countries may be entitled to access your
          information.
        </p>
        <p>
          If you are located in the EEA, the UK or Switzerland, we comply with
          applicable laws to provide an adequate level of data protection for the transfer
          of your Personal Data to the US. Where applicable law requires us to ensure that
          an international data transfer is governed by a data transfer mechanism, we use
          one or more of the following mechanisms: EU Standard Contractual Clauses with a
          data recipient outside the EEA or the UK, verification that the recipient has
          implemented Binding Corporate Rules, or verification that the recipient adheres
          to the EU-US and Swiss-US Privacy Shield Framework.
        </p>
        <p>
          <strong>CHILDREN</strong>
        </p>
        <p>
          We do not knowingly collect personal information from users under the age of
          thirteen.
        </p>
        <p>
          <strong>LINKS TO OTHER WEBSITES</strong>
        </p>
        <p>
          The Services may provide the ability to connect to other websites. These
          websites may operate independently from us and may have their own privacy
          notices or policies, which we strongly suggest you review. If any linked website
          is not owned or controlled by us, we are not responsible for its content, any
          use of the website or the privacy practices of the operator of the website.
        </p>
        <p>
          <strong>YOUR RIGHTS</strong>
        </p>
        <p>
          Depending on the jurisdiction in which you are located, you may have certain
          rights with respect to your personal information. Subject to applicable law,
          these rights may include:
        </p>
        <ul>
          <li>The right to know what personal information about you we have collected,
              disclosed, and or sold, as applicable;
          </li>
          <li>The right to obtain a copy of your personal information;</li>
          <li>The right to have your personal information deleted;</li>
          <li>The right to rectify or update your personal information;</li>
          <li>The right to restrict the processing of your personal information;</li>
          <li>The right to object to the processing of your personal information;
              and
          </li>
          <li>The right to withdraw your consent for processing (where processing is
              based on your consent). Please note that if you withdraw your consent, we may
              not be able to provide you the services for which it was necessary to process
              your information based on your consent.
          </li>
        </ul>
        <p>
          To exercise any of the rights described above, please submit a verifiable
          request to us at <a className="textLink" href="mailto:seerevvision@gmail.com" target="_blank" rel="noopener noreferrer">seerevvision@gmail.com</a>.
        </p>
        <p>
          The verifiable request must describe your request with sufficient detail
          that allows us to properly understand, evaluate and respond to it, and provide
          sufficient information that allows us to reasonably verify you are the person
          about whom we collected the PI or an authorized representative. Please, however,
          refrain from sending us sensitive PI in your request.
        </p>
        <p>
          Where permitted by law, you may use an authorized agent to submit a request
          on your behalf if you have provided the authorized agent signed permission to do
          so. We may not be able to fulfill your request if we cannot verify your identity
          or authority to make the request and confirm that the personal information
          relates to. Making a verifiable request does not require you to create an
          account with us. We will only use personal information provided in a verifiable
          request to verify the requestor’s identity or authority to make the
          request.
        </p>
        <p>
          We will respond to your request within a reasonable timeframe in accordance
          with applicable law. We reserve the right to charge an appropriate fee for
          complying with a request where permitted by applicable law, and to deny a
          request where it may be manifestly unfounded, excessive, or unwarranted under
          applicable law.
        </p>
        <p>
          We hope that you won’t ever need to, but if you do want to complain about
          our use of personal information, please send us a message with the details of
          your complaint using the contact email <a className="textLink" href="mailto:seerevvision@gmail.com" target="_blank" rel="noopener noreferrer">seerevvision@gmail.com</a>.
        </p>
        <p>
          Applicable laws may also give you the right to lodge a complaint with the
          data protection authority in your country.  
        </p>
        <p>
          <strong>LOCATION</strong>
        </p>
        <p>
          When you first launch any features of our Services that collect location
          information, you will be asked to consent to our collection of this information.
          If you initially consent to our collection of location information, you can
          subsequently stop the collection of this information by changing the preferences
          on your mobile device. If you do so, certain features of our Services will no
          longer function. You may also stop our collection of location information by
          following the standard uninstall process to remove our Services from your
          device.
        </p>
        <p>
          <strong>COOKIES</strong>
        </p>
        <p>
          Most web browsers are set to accept cookies by default. If you prefer, you
          can usually choose to set your browser to remove or reject browser cookies.
          Please note that if you choose to remove or reject cookies, this could affect
          the availability and functionality of our website.
        </p>
        <p>
          <strong>CONTACT</strong>
        </p>
        <p>
          To contact us, please send a message to <a className="textLink" href="mailto:seerevvision@gmail.com" target="_blank" rel="noopener noreferrer">seerevvision@gmail.com</a>
        </p>
      </Container>
    )
  }
}