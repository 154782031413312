import React from 'react'
import styled from 'styled-components/macro'

const StyledLogo = styled.a`
  display: inline-flex;
  margin: 50px 0 0 50px;
  text-decoration: none;
  p {
    margin-left: 13px;
    font-family: 'Apercu Medium', sans-serif
  }
  @media (max-width:767px) {
    margin: 50px 0 0 30px;
  }
`

export default class Logo extends React.Component {
  render() {
    return (
      <StyledLogo href="/">
        <svg width="43" height="39" viewBox="0 0 43 39" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M42 23.7941C42 25.0118 41.0256 26 39.825 26H15.175C13.9744 26 13 25.0118 13 23.7941V3.20588C13 1.98971 13.9744 1 15.175 1H39.825C41.0256 1 42 1.98971 42 3.20588V23.7941Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M41 2L27.5 15L14 2" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M11.2632 9H8.42105C7.63947 9 7 9.64688 7 10.4375V30.5625C7 31.3517 7.63947 32 8.42105 32H32.5789C33.3605 32 34 31.3517 34 30.5625V27.6875" stroke="white" strokeOpacity="0.5" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
          <path d="M5.26316 15H2.42105C1.63947 15 1 15.6469 1 16.4375V36.5625C1 37.3517 1.63947 38 2.42105 38H26.5789C27.3605 38 28 37.3517 28 36.5625V33.6875" stroke="white" strokeOpacity="0.25" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
        </svg>
        <p>Cold Email Grader</p>
      </StyledLogo>
    )
  }
}