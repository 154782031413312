import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import bg_footer from '../assets/img/bg-footer.svg'

const StyledFooter = styled.div`
  width: 100%;
  height: 190px;
  background: url(${bg_footer});
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-family: 'Apercu Light', sans-serif;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.133333px;
  color: #2E2E2E;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  svg {
    margin-top: 10px;
  }
  @media (max-width:767px) {
    height: 100px;
  }
`
const StyledLink = styled(Link)`
    font-family: 'Apercu Medium', sans-serif;
    color: #9A67C6;
    border-bottom: 1px solid transparent;
    text-decoration: none;
    transition: all 200ms ease;
  :first-of-type {
    margin-right: 10px;
  }
  :hover {
    border-bottom: 1px solid #9A67C6;
  }
`

export default class Footer extends React.Component {
  render() {
    return (
      <StyledFooter>
        &copy;2020 REVVISION, LLC
        <div>
          <StyledLink to="/privacy-policy">Privacy Policy</StyledLink> 
          <StyledLink to="/terms">Terms of Service</StyledLink>
        </div>
        <a href="https://twitter.com/coldemailgrader" target="_blank" rel="noopener noreferrer">
          <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.1367 4.59375C16.8398 4.06641 17.4727 3.43359 18 2.66016C17.2969 2.97656 16.5938 3.1875 15.8906 3.25781C16.6641 2.76562 17.2266 2.09766 17.5078 1.21875C16.7695 1.64062 15.9961 1.95703 15.1523 2.09766C14.8008 1.74609 14.3789 1.46484 13.9219 1.25391C13.4648 1.04297 12.9727 0.9375 12.4453 0.9375C11.7773 0.9375 11.1797 1.11328 10.6172 1.42969C10.0547 1.78125 9.59766 2.23828 9.28125 2.80078C8.92969 3.36328 8.78906 3.99609 8.78906 4.62891C8.78906 4.91016 8.78906 5.19141 8.85938 5.47266C7.34766 5.40234 5.94141 5.05078 4.60547 4.34766C3.26953 3.67969 2.17969 2.76562 1.26562 1.60547C0.914062 2.20312 0.738281 2.83594 0.738281 3.46875C0.738281 4.10156 0.878906 4.69922 1.19531 5.22656C1.47656 5.78906 1.89844 6.21094 2.39062 6.5625C1.79297 6.5625 1.23047 6.38672 0.738281 6.07031V6.14062C0.738281 7.01953 1.01953 7.79297 1.58203 8.46094C2.14453 9.16406 2.84766 9.58594 3.69141 9.76172C3.33984 9.83203 3.02344 9.86719 2.70703 9.86719C2.49609 9.86719 2.25 9.86719 2.03906 9.83203C2.25 10.5703 2.67188 11.168 3.30469 11.6602C3.9375 12.1523 4.64062 12.3633 5.48438 12.3633C4.11328 13.418 2.56641 13.9453 0.878906 13.9453C0.527344 13.9453 0.246094 13.9453 0 13.9102C1.6875 15.0352 3.58594 15.5625 5.66016 15.5625C7.80469 15.5625 9.70312 15.0352 11.3906 13.9102C12.9023 12.9258 14.0977 11.625 14.9414 9.9375C15.75 8.39062 16.1719 6.73828 16.1719 5.05078C16.1719 4.83984 16.1367 4.69922 16.1367 4.59375Z" fill="#1DA1F2"/>
          </svg>
        </a>
      </StyledFooter>
    )
  }
}