import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Grader from './pages/Grader.js'
import PrivacyPolicy from './pages/PrivacyPolicy.js';
import TermsOfService from './pages/TermsOfService.js';

const Routes = () => {
  return (
    <Switch>
      <Route exact path='/' component={Grader}></Route>
      <Route exact path='/privacy-policy' component={PrivacyPolicy}></Route>
      <Route exact path='/terms' component={TermsOfService}></Route>
      <Redirect to="/" />
    </Switch>
  );
}

export default Routes;